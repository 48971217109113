.main {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
}

.row-container {
  display: flex;
  flex-direction: row;
}

.sidebar {
  background-color: #313a46;
  display: flex;
  flex-direction: column;
  width: 150px;
  height: 100vh;
}

.sidebar-img {
  background: url(/src/public/img/aesir-icon.png) no-repeat;
  background-size: contain;
  min-width: 100;
  max-width: 100;
  height: 100px;
  margin: 25px;
}

.sidebar-item {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  margin-top: 7px;
  margin-bottom: 7px;
  margin-right: 7px;
  color: azure;
  font-weight: bold;
  list-style-type: none;
  cursor: pointer;
}

.sidebar-item.selected {
  color: #2ad4da;
  text-shadow: 1px 1px 6px #55c0c4a1;
}

.fileviewer {
  background-color: #fafbfe;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 5px;
}

.textarea {
  box-shadow: 3px 3px 10px #313a46a1;
  resize: none;
  margin: 30px;
  margin-top: 5px;
  width: 80%;
  border-radius: 5px;
  border: double 10px grey;
  padding: 20px;
  flex: 1;
}

.navbar {
  margin-bottom: 10px;
  box-shadow: 1px 1px 10px #313a4650;
  background-color: #ffffff;
  display: flex;
  justify-content: right;
  align-items: center;
  padding-right: 40px;
}
.list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  margin-left: 10px;
  padding: 0 0 0 15px;
}

.filebar {
  margin-top: 10px;
  color: #313a46;
  font-weight: 200;
  font-size: large;
  background-color: #fafbfe;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  width: 80%;
}
.header-config {
  width: 80%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.buttons-bar {
  color: #fafbfe;
  display: flex;
  width: 80%;
  margin: auto;
  flex-direction: row-reverse;
}

@media (max-width: 700px) {
  .row-container {
    flex-direction: column;
    height: 100vh;
  }
  .sidebar {
    flex-direction: row;
    width: 100%;
    height: fit-content;
    align-items: center;
    justify-content: space-between;
  }
  .sidebar-img {
    background: url(/src/public/img/aesir-icon.png) no-repeat;
    background-size: contain;
    min-width: 100;
    max-width: 100;
    height: 70px;
    margin: 10px;
    flex: 1;
  }
  .textarea {
    width: 70%;
    margin: auto;
  }

  .main {
    width: 100%;
  }
  .list {
    padding: 0 0 0 0;
    margin-right: 30px;
  }
}
