.custom-modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  box-shadow: 10px 5px 10px #294d7aa1;
  max-width: 60%;
  margin: 100px auto;
  position: relative;
  z-index: 1000;
  background-color: #fafbfe;
  padding-top: 5px;
}

@media (max-width: 700px) {
  .custom-modal-content {
    max-width: 95%;
  }
}
.custom-modal {
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  transition: all 1s;
  pointer-events: none;
}

.custom-modal.visible {
  opacity: 1 !important;
  pointer-events: inherit;
}
